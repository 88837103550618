import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  DictAchievementStatus,
  DictDirectionActivity,
  DictDocumentType,
  WorkType
} from '../../models/portfolio/dict.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DictService {
  baseUrl = `${environment.apiEndpoint}${environment.portfolio.apiPaths.portfolioDict}`;

  constructor(private http: HttpClient) {}

  getDirectionActivities(): Observable<DictDirectionActivity[]> {
    return this.http.get<DictDirectionActivity[]>(`${this.baseUrl}/DirectionActivity`);
  }

  getDocumentTypes(): Observable<DictDocumentType[]> {
    return this.http.get<DictDocumentType[]>(`${this.baseUrl}/DocumentType`);
  }

  getStatuses(): Observable<DictAchievementStatus[]> {
    return this.http.get<DictAchievementStatus[]>(this.baseUrl + '/DictAchievementStatus');
  }

  getTypes(): Observable<WorkType[]> {
    return this.http.get<WorkType[]>(this.baseUrl + '/WorkType');
  }
}
