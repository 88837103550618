import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {createMap} from '../../../../helpers/map-helper';
import {ApplicationApprovalSettings} from '../../../../models/mfc/applicationForm/approvalList.model';
import {ApprovalStatus} from '../../../../models/mfc/dicts/approval-status.model';
import {ApprovalStatusService} from '../../../../services/mfc/dicts/approval-status.service';
import {SignatoryRoleService} from '../../../../services/mfc/dicts/signatory-role.service';
import {RoleService} from '../../../../services/mfc/dicts/role.service';
import {ApplicationEditFormService} from '../../../../services/mfc/application-edit-form.service';

@Component({
  selector: 'mfc-approval-list',
  templateUrl: './approval-list.component.html',
  styleUrls: ['./approval-list.component.scss']
})
export class ApplicationFormApprovalListComponent implements OnInit, OnChanges {

  @Input() public approvalSettings: ApplicationApprovalSettings[] = [];

  protected dictApprovalStatuses: ApprovalStatus[] = [];
  protected dictRolesMap: Map<unknown, unknown> = new Map([]);
  protected dictSignatoryRolesMap: Map<unknown, unknown> = new Map([]);

  public loading = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private approvalStatusService: ApprovalStatusService,
    private dictRoleService: RoleService,
    private dictSignatoryRoleService: SignatoryRoleService,
    private editFormService: ApplicationEditFormService
  ) { }

  async ngOnInit() {
    await this.getRoles();
    await this.getSignatoryRoles();
    this.getApprovalStatuses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.approvalSettings = this.editFormService.mapApprovalSettings(this.approvalSettings);
  }

  private async getRoles() {
    await lastValueFrom(this.dictRoleService.getRoles()).then((response) => {
      this.dictRolesMap = createMap({value: response, valueField: 'id', textField: 'name'});
    });
  }

  private async getSignatoryRoles() {
    await lastValueFrom(this.dictSignatoryRoleService.getSignatoryRoles()).then((response) => {
      this.dictSignatoryRolesMap = createMap({value: response, valueField: 'externalId', textField: 'name'});
    });
  }

  protected getApprovalStatuses() {
    this.approvalStatusService.getApprovalStatuses().subscribe((response) => {
      this.dictApprovalStatuses = response;
      this.cdr.detectChanges();
    });
  }
}
