<div class="container">
    <div [formGroup]="formGroup" class="fields-container">
        <kendo-textarea formControlName="text" placeholder="Введите текст комментария"></kendo-textarea>
        <kendo-fileselect
                [restrictions]="fileRestrictions"
                [accept]="fileRestrictions.accept"
                (valueChange)="filesChanged($event)"
                formControlName="files">
        </kendo-fileselect>
    </div>
    <h6 *ngIf="!filesValid" class="note error">Вы закрепили больше {{fileRestrictions.maxCount}} файлов</h6>
    <div class="actions">
        <h6 class="note">{{fileNotes}}</h6>
        <button [disabled]="!(filesValid && formGroup.valid)" (click)="onSave()" themeColor="primary" kendoButton>Отправить</button>
    </div>
    <div class="comments" *ngIf="comments.length">
        <ng-container *ngFor="let comment of comments;">
            <div [ngClass]="{self: comment.studentId}" class="block">
                <span class="item">
                    <span class="text">
                        <h6>{{comment.text}}</h6>
                        <h6 *ngIf="comment.studentId" (click)="deleteComment(comment)" class="delete">
                            Удалить<kendo-icon name="close"></kendo-icon>
                        </h6>
                    </span>
                    <span class="files" *ngIf="comment.files.length">
                        <span class="content" *ngFor="let file of comment.files">
                            <kendo-icon name="attachment"></kendo-icon>
                            <h6 [title]="file.fileName" (click)="getFile(comment.id, file)" class="attachment">{{file.fileName}}</h6>
                        </span>
                    </span>
                    <h6 class="fio">{{comment.author}}, {{comment.sentAt | date:'dd.MM.yyyy HH:mm'}}</h6>
                </span>
            </div>
        </ng-container>
    </div>
</div>