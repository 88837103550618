export class CostTableRow {
  expenseType = '';
  quantity = '';
  costPerStudent = 0;
  costPerGroup = 0;
  totalCost = 0;
}

export class StudentTableRow {
  fullName = '';
  facultyId = '';
  eduGroupName = '';
}