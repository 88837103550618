import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {handleError} from '../../helpers/errorHandle-helper';


@Injectable({
  providedIn: 'root'
})
export class ApplicationConstructorDocumentService {
  private baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.applicationConstructor}/`;

  constructor(private http: HttpClient) { }

  public getDocument(constructorId: string, documentId: string) {
    return this.http.get(`${this.baseUrl}GetClearDocument/${constructorId}/${documentId}`, {responseType: 'blob'})
      .pipe(catchError(handleError));
  }
}
