import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {handleError} from "../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class RegulationFileService {

  baseUrl = `${environment.apiEndpoint}${environment.oferta.apiPaths.regulationFile}`

  constructor(private http: HttpClient) { }

  public getRegulationFile(id: string) {
    return this.http.get(`${this.baseUrl}/File/${id}`, {responseType: 'blob'})
      .pipe(catchError(handleError));
  }
}
