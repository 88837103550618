import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError} from "rxjs";
import {handleError} from "../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class AttachedFileService {
  private baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.commonApplicationConstructor}/AttachedFile/`;

  constructor(private http: HttpClient) { }

  public downloadFile(fileId: string) {
    return this.http.get(`${this.baseUrl}${fileId}`, {responseType: 'blob'})
      .pipe(catchError(handleError));
  }
}