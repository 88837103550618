export enum ApplicationApprovalTypeEnum {
  // Отправка на подпись
  SendingForSignature = 1,
  // Отправка на согласования
  SendingForApproval,
  // Согласование по кнопке
  ButtonApproval,
  // Подписание физической подписью
  SigningAPhysicalSignature,
  // Подписание простой ЭП
  SigningASimpleElectronicSignature,
  // Подписание усиленной ЭП
  SigningOfAnEnhancedElectronicSignature,
  // Без согласования
  WithoutApproval
}
