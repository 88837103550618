import {Injectable} from '@angular/core';
import {ApplicationList, Filter, QueryOptions} from '../../models/mfc/home/application.model';
import {HttpClient} from '@angular/common/http';
import {TimeZoneFix} from '../../helpers/date-helper';
import {catchError, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {handleError} from '../../helpers/errorHandle-helper';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.application}/`;

  constructor(private http: HttpClient) { }

  private timeZoneFix(filter: Filter) {
    if (filter.createdAt) {
      if (filter.createdAt.start) filter.createdAt.start = TimeZoneFix(filter.createdAt.start);
      if (filter.createdAt.end) filter.createdAt.end = TimeZoneFix(filter.createdAt.end);
    }

    if (filter.statusDate) {
      if (filter.statusDate.start) filter.statusDate.start = TimeZoneFix(filter.statusDate.start);
      if (filter.statusDate.end) filter.statusDate.end = TimeZoneFix(filter.statusDate.end);
    }
  }

  public getApplications(options: QueryOptions): Observable<ApplicationList> {
    this.timeZoneFix(options.filter);
    return this.http.post<ApplicationList>(`${this.baseUrl}GetAll`, options)
      .pipe(catchError(handleError));
  }
}
