<form [formGroup]="groupsFormGroup">
  <ng-container *ngFor="let group of groups; let i = index" formArrayName="groups">
    <h4 class="subtitle">{{group.name}}</h4>
    <div class="group" [formGroupName]="i">
      <ng-container *ngFor="let attachedFile of group.attachedFiles">
          <span class="field field_file">
            <h3>{{attachedFile.name}}</h3>
            <button (click)="downloadAttachedFile(attachedFile)" icon="download" kendoButton>Скачать</button>
          </span>
      </ng-container>
      <ng-container *ngFor="let fieldCode of group.fieldCodes">
        <span class="field">
          <h4>{{studentFieldsMap.get(fieldCode.code) ?? fieldCode.code}}</h4>
          <h4 class="text">{{fieldCode.value}}</h4>
        </span>
      </ng-container>
      <div class="orders">
        <span class="text" *ngFor="let order of group.orders">{{order.orderName}}</span>
      </div>
      <ng-container *ngFor="let property of formProperties(i).controls; let j = index" formArrayName="properties">
        <span [ngClass]="{
                field: true,
                table: property.value.propertyTypeEnum === KendoProperties.Table
              }">
          <h4>{{property.value.name}}</h4>
          <span [ngClass]="{
                  input: true,
                  'input_text': property.value.propertyTypeEnum === KendoProperties.Textbox
                }">
            <ng-container [formGroupName]="j" [ngSwitch]="property.value.propertyTypeEnum">
              <h4 class="text" *ngSwitchDefault>{{property.get('propertyValue')?.value}}</h4>

              <kendo-dropdownlist *ngSwitchCase="KendoProperties.Dropdown"
                                  [data]="(property.value.dict ?? [])"
                                  [valuePrimitive]="true"
                                  (valueChange)="valueChange()"
                                  textField="name"
                                  valueField="id"
                                  formControlName="propertyValue">
              </kendo-dropdownlist>

              <kendo-combobox *ngSwitchCase="KendoProperties.Combobox"
                              #combobox
                              [data]="(property.value.dict ?? [])"
                              [valuePrimitive]="true"
                              [kendoDropDownFilter]="filterSettings"
                              [openOnFocus]="combobox"
                              (valueChange)="valueChange()"
                              textField="name"
                              valueField="id"
                              formControlName="propertyValue">
              </kendo-combobox>

              <ng-container [ngSwitch]="property.value.dictPropertyEnum" *ngSwitchCase="KendoProperties.Textbox">
                <kendo-combobox *ngSwitchCase="DictProperty.Specialization"
                                #specialization
                                [data]="specializations"
                                [kendoDropDownFilter]="filterSettings"
                                [openOnFocus]="specialization"
                                (valueChange)="valueChange()"
                                [placeholder]="property.get('propertyValue')?.value"
                                formControlName="propertyValue">
                </kendo-combobox>
                <kendo-textbox *ngSwitchDefault
                               (valueChange)="valueChange()"
                               formControlName="propertyValue">
                </kendo-textbox>
              </ng-container>

              <kendo-textarea *ngSwitchCase="KendoProperties.Textarea"
                              (valueChange)="valueChange()"
                              formControlName="propertyValue">
              </kendo-textarea>

              <kendo-datepicker #datePicker
                                *ngSwitchCase="KendoProperties.Date"
                                [openOnFocus]="datePicker"
                                (valueChange)="valueChange()"
                                formControlName="propertyValue">
              </kendo-datepicker>

              <ng-container [ngSwitch]="property.value.dictPropertyEnum" *ngSwitchCase="KendoProperties.DateRange">
                <date-range-field [ngClass]="{duration: property.value.dictPropertyEnum === DictProperty.Duration}"
                                  [value]="property.get('propertyValue')?.value"
                                  (valueChange)="dateRangeChange($event, property)"
                                  [required]="property.value.required"
                                  [editable]="property.value.editable">
                </date-range-field>
                <span *ngSwitchCase="DictProperty.Duration" class="duration-text">
                  продолжительность
                  <span class="duration-text--days">{{getDuration(property.get('propertyValue')?.value)}}</span>
                  дня(-ей)
                </span>
              </ng-container>

              <kendo-numerictextbox *ngSwitchCase="KendoProperties.Number"
                                    [decimals]="0"
                                    [changeValueOnScroll]="false"
                                    [min]="0"
                                    (valueChange)="valueChange()"
                                    format="false"
                                    formControlName="propertyValue">
              </kendo-numerictextbox>

              <kendo-numerictextbox *ngSwitchCase="KendoProperties.Decimal"
                                    [changeValueOnScroll]="false"
                                    [min]="0"
                                    format="#.###"
                                    (valueChange)="valueChange()"
                                    formControlName="propertyValue">
              </kendo-numerictextbox>

              <span *ngSwitchCase="KendoProperties.File">
                <ng-container *ngTemplateOutlet="fileTemplate"></ng-container>
              </span>

              <span *ngSwitchCase="KendoProperties.Files">
                <ng-container *ngTemplateOutlet="fileTemplate"></ng-container>
              </span>

              <ng-template #fileTemplate>
                <kendo-fileselect [accept]="fileRestrictions.accept"
                                  [restrictions]="fileRestrictions"
                                  [multiple]="property.value.propertyTypeEnum === KendoProperties.Files"
                                  (valueChange)="fileChange(property.get('propertyValue'), $event)"
                                  formControlName="propertyValue">
                  <ng-template kendoFileSelectFileInfoTemplate let-files>
                    <div>{{ files[0].name }}</div>
                  </ng-template>
                </kendo-fileselect>
                <span *ngIf="property.value.editable" class="restrictions">До {{
                  property.value.propertyTypeEnum === KendoProperties.Files ? fileRestrictions.maxCount + ' файлов' : '1 файла'
                  }} форматов {{fileRestrictions.accept}}</span>
                <a *ngIf="property.get('propertyValue')?.value?.length" class="file-link" (click)="downloadFiles(property.get('propertyValue')?.value)">
                  Скачать {{property.value.propertyTypeEnum === KendoProperties.Files ? 'все' : ''}}
                </a>
              </ng-template>

              <kendo-multiselect *ngSwitchCase="KendoProperties.Multiselect"
                                 [data]="(property.value.dict ?? [])"
                                 [valuePrimitive]="true"
                                 [kendoDropDownFilter]="filterSettings"
                                 (valueChange)="valueChange()"
                                 textField="name"
                                 valueField="id"
                                 formControlName="propertyValue">
                <ng-template kendoMultiSelectTagTemplate let-dataItem>
                  <span [title]="dataItem.name" class="template">
                    {{ dataItem.name }}
                  </span>
                </ng-template>
              </kendo-multiselect>

              <input *ngSwitchCase="KendoProperties.Boolean"
                     (click)="valueChange()"
                     type="checkbox" kendoCheckBox
                     formControlName="propertyValue">
              <ng-container [ngSwitch]="property.value.dictPropertyEnum" *ngSwitchCase="KendoProperties.Table">
                <mfc-student-table
                        [editable]="property.value.editable"
                        [data]="property.get('propertyValue')?.value ?? []"
                        (value)="tableValueChanged($event, property)"
                        *ngSwitchCase="DictProperty.StudentTable">
                </mfc-student-table>
                <mfc-cost-table
                        [editable]="property.value.editable"
                        [data]="property.get('propertyValue')?.value ?? []"
                        (value)="tableValueChanged($event, property)"
                        *ngSwitchCase="DictProperty.CostTable">
                </mfc-cost-table>
              </ng-container>

            </ng-container>
            <span *ngIf="property.value.editable && property.value.required" class="required">*</span>
            <kendo-icon *ngIf="property.value.tooltip"
                        [title]="property.value.tooltip"
                        kendoTooltip
                        name="info"
                        tooltipClass="tooltip-popup"
                        position="right"
                        size="medium">
            </kendo-icon>
          </span>
        </span>
      </ng-container>
    </div>
  </ng-container>
</form>
