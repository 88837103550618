import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CellClickEvent, GridDataResult, PagerSettings} from '@progress/kendo-angular-grid';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {PageChangeEvent} from '@progress/kendo-angular-pager';
import {ApplicationStatusesEnum} from '../../../models/mfc/enums/application-statuses.enum';
import {
  Filter,
  QueryOptions,
  Sort,
  sortModeValues
} from '../../../models/mfc/home/application.model';
import {ApplicationService} from '../../../services/mfc/application.service';
import {ApplicationCategory} from '../../../models/mfc/dicts/application-category.model';
import {ApplicationType} from '../../../models/mfc/dicts/application-type.model';
import {ApplicationStatus} from '../../../models/mfc/dicts/application-status.model';
import {ApplicationCategoryService} from '../../../services/mfc/dicts/applicationCategory.service';
import {ApplicationTypeService} from '../../../services/mfc/dicts/applicationType.service';
import {ApplicationStatusService} from '../../../services/mfc/dicts/application-status.service';
import {NotificationsService} from '../../../services/Notifications/notifications.service';


@Component({
    selector: 'app-mfc-home',
    templateUrl: './mfc-home.component.html',
    styleUrls: ['./mfc-home.component.scss']
})

export class MFCHomeComponent implements OnInit {

  protected loading = false;
  protected skip = 0;
  protected pageSize = 10;
  protected state: State = {
    skip: this.skip,
    take: this.pageSize,
  };
  protected pagerSettings: PagerSettings = {
    type: 'numeric',
    buttonCount: 5,
    pageSizes: [10, 20, 50],
    info: true,
    previousNext: true,
    position: 'bottom'
  };

  protected sort: SortDescriptor[] = [
    {
      field: 'createdAt',
      dir: 'desc',
    },
  ];

  // TODO
  // protected sortSettings: MultipleSortSettings = {
  //   mode: 'multiple',
  //   initialDirection: 'desc',
  //   allowUnsort: true,
  //   showIndexes: true,
  // };

  protected options: QueryOptions = {
    page: {number: 1, size: this.pageSize},
    sortParameters: this.patchSort(this.sort[0]),
    filter: {
      number: null,
      createdAt: null,
      category: null,
      type: null,
      statuses: null,
      statusDate: null,
    }
  };

  protected applicationCategories: ApplicationCategory[] = [];
  protected applicationTypes: ApplicationType[] = [];
  protected filterApplicationTypes: ApplicationType[] = [];
  protected applicationStatuses: ApplicationStatus[] = [];

  protected totalCount = 0;
  protected gridData: GridDataResult = {
    data: [],
    total: this.totalCount
  };

  protected readonly StatusEnum = ApplicationStatusesEnum;
  //TODO: Убрать после подключения изменений на беке
  protected statusMap = new Map<ApplicationStatusesEnum, string>([]);

  constructor(
    private router: Router,
    private notificationService: NotificationsService,
    private applicationCategoryService: ApplicationCategoryService,
    private applicationTypeService: ApplicationTypeService,
    private applicationStatusService: ApplicationStatusService,
    private applicationService: ApplicationService,
  ) { }

  ngOnInit() {
    this.getDicts();
    this.getApplications();
  }

  private getDicts() {
    this.applicationCategoryService.getApplicationCategories().subscribe((response) =>
      this.applicationCategories = response);
    this.applicationTypeService.getApplicationTypes().subscribe((response) => {
      this.applicationTypes = response;
      this.filterApplicationTypes = response;
    });
    this.applicationStatusService.getAll().subscribe((response) => {
      this.applicationStatuses = response;
      this.applicationStatuses.forEach((item) => {
        this.statusMap.set(item.applicationStatusEnum, item.name);
      });
    });
  }

  private getApplications() {
    this.loading = true;
    this.applicationService.getApplications(this.options).subscribe({
      next: (response) => {
        this.totalCount = response.totalApplicationCount;
        this.gridData = {
          data: response.applications,
          total: this.totalCount
        };

        this.loading = false;
      },
      error: (error) => {
        this.notificationService.showError(error);
        this.loading = false;
      }
    });
  }

  protected pageChange(event: PageChangeEvent) {
    this.state = {
      skip: event.skip,
      take: event.take
    }

    this.options.page = {
      number: event.skip / event.take + 1,
      size: event.take
    };
    this.getApplications();
  }

  private patchSort(sort: SortDescriptor): Sort {
    return {
      columnName: `${sort.field[0].toUpperCase()}${sort.field.slice(1)}`,
      mode: sortModeValues.get(sort.dir ?? '')
    } as Sort;
  }

  protected sortChange(sort: SortDescriptor[]) {
    this.sort = sort;
    this.options.sortParameters = this.sort
      .map((item) => this.patchSort(item))
      .find((item) => !!item.mode) ?? null;
    this.getApplications();
  }

  protected filterChange(value: unknown, filter: keyof Filter) {
    this.options.filter[filter] = Array.isArray(value) && !value.length ? null : value;

    this.state = {
      skip: this.skip,
      take: this.pageSize
    }
    this.pageChange({skip: this.skip, take: this.pageSize});
  }

  protected categoryChange(value: string[]) {
    if (value.length) {
      const categoryIds = this.applicationCategories.filter((item) =>
        value.includes(item.id)).map((item) => item.id);
      this.filterApplicationTypes = this.applicationTypes.filter((item) =>
        categoryIds.includes(item.dictApplicationCategoryId));
      this.options.filter.type = this.options.filter.type?.filter((id) =>
        this.filterApplicationTypes.some((item) => item.id === id)) ?? null;
    } else {
      this.filterApplicationTypes = this.applicationTypes;
    }
    this.filterChange(value, 'category');
  }

  protected addApplication() {
    this.router.navigateByUrl('/mfc/applicationTypeSelect');
  }

  protected navigateToSelectedApplication({dataItem}: CellClickEvent) {
    window.open(`mfc/applicationForm/${dataItem.id}`, '_blank');
  }
}
