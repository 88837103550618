import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {JoinStudPersonCommand} from "../../models/oferta/usersignature/commands/joinStudPersonCommand";

@Injectable({
  providedIn: 'root'
})
export class UserSignatureService {

  baseUrl = `${environment.apiEndpoint}${environment.oferta.apiPaths.userSignature}`

  constructor(private http: HttpClient) { }

  studPersonHasSignature(query: HttpParams): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + '/StudPersonHasUserSignature', {params: query});
  }

  joinStudPersonToRegulation(command: JoinStudPersonCommand) {
    return this.http.post(this.baseUrl + '/JoinStudPerson', command)
  }
}
