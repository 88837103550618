import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TypeReceipt} from '../../../models/mfc/dicts/type-receipt.model';


@Injectable({
  providedIn: 'root'
})
export class TypeReceiptService {
  private readonly baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.dict}/TypeReceipt/`;

  constructor(private http: HttpClient) { }

  public getAll(): Observable<TypeReceipt[]> {
    return this.http.get<TypeReceipt[]>(this.baseUrl);
  }

  public getById(id: string): Observable<TypeReceipt> {
    return this.http.get<TypeReceipt>(`${this.baseUrl}${id}`);
  }
}
