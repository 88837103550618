import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {SystemSettingNotification} from "../../models/home/systemsettingnotification.model";

@Injectable({
  providedIn: 'root'
})
export class SystemSettingNotificationService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.systemsettingnotification}`;

  constructor(private http: HttpClient) { }

  public GetSystemsSettingContactNotification(): Observable<SystemSettingNotification> {
    return this.http.get<SystemSettingNotification>(this.baseUrl + '/GetSystemsSettingContactNotification/');
  }

  public EditSystemsSettingContactNotification(request: SystemSettingNotification) {
    return this.http.post(this.baseUrl + '/EditSystemsSettingContactNotification/', request)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}
