<h1>Заявка</h1>

<div class="content">
  <button (click)="goBack()" kendoButton>Назад</button>
  <div class="category-list" #content>
    <div *ngFor="let category of data">
      <h4>{{ category.name }}</h4>
      <div class="category-content">
        <div class="type-list" *ngIf="category.dictApplicationTypes?.length">
          <ng-container *ngFor="let type of category.dictApplicationTypes">
            <a (click)="createApplication(category.externalId, type.externalId)">{{ type.name }}</a>
          </ng-container>
        </div>
        <div class="another">
          <span class="subtitle">Другие типы</span>
          <span class="explanation-text">{{ category.tooltip }}</span>
          <a (click)="createApplication(category.externalId)">Подать заявку</a>
        </div>
      </div>
    </div>
  </div>
  <button *ngIf="content.clientHeight > contentHeightCoefficient * window.innerHeight"
          (click)="goBack()" kendoButton>
    Назад
  </button>
</div>
