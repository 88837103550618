import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { LKStudent } from 'src/app/models/profile/lkstudent.model';
import { environment } from 'src/environments/environment';
import {StudentInfoDto} from "../../models/home/StudentInfoDto.model";

@Injectable({
  providedIn: 'root'
})
export class LKStudentService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.Student}`;

  constructor(private http: HttpClient) { }

  /**
   * Get Student by Id
   * @param id StudentId
   */
  public getStudentById(id: number): Observable<LKStudent> {
    return this.http.get<LKStudent>(this.baseUrl + '/GetStudentByID/' + id)
      .pipe(
        catchError(this.handleError));
  }
  //Get Token For External Service
  public getTokenForExternalServices(): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/GetTokenForExternalService`, { responseType: 'text' as 'json' });
  }

  /**
   * Get Student by login
   */
  public getStudentByLogin(): Observable<LKStudent[]> {
    return this.http.get<LKStudent[]>(this.baseUrl + '/GetAllStudentsByLogin')
      .pipe(
        catchError(this.handleError));
  }

  /**
   * Получение текущего студента
   */
  public getCurrentStudent(): Observable<LKStudent[]> {
    return this.http.get<LKStudent[]>(this.baseUrl + '/GetAllCurrentStudents')
      .pipe(
        catchError(this.handleError));
  }

  public GetStudentInfo(studentId: string): Observable<StudentInfoDto> {
    return this.http.get<StudentInfoDto>(this.baseUrl + '/GetStudentInfo/' + studentId)
      .pipe(
        catchError(this.handleError));
  }

  // Получение всех студентов
  public getStudents(): Observable<LKStudent[]> {
    return this.http.get<LKStudent[]>(this.baseUrl + '/GetStudents/')
      .pipe(
        catchError(this.handleError));
  }

  /**
   * Обработчик ошибок
   * @param error
   */
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
