<div>
    <table class="table">
        <tr>
            <th>Номер зачетной книжки</th>
            <td>{{student.studentNumber}}</td>
        </tr>
        <tr>
            <th>Источник финансирования</th>
            <td>N/A</td>
        </tr>
        <tr>
            <th>Форма обучения</th>
            <td>N/A</td>
        </tr>
        <tr>
            <th>Факультет/институт</th>
            <td>N/A</td>
        </tr>
        <tr>
            <th>Курс</th>
            <td>N/A</td>
        </tr>
        <tr>
            <th>Группа</th>
            <td>N/A</td>
        </tr>
        <tr>
            <th>Направление обучения</th>
            <td>N/A</td>
        </tr>
        <tr>
            <th>Направленность (профиль)</th>
            <td>N/A</td>
        </tr>
        <tr>
            <th>Кафедра</th>
            <td>N/A</td>
        </tr>
        <tr>
            <th>Академический статус</th>
            <td>N/A</td>
        </tr>
        <tr>
            <th>Заказчик целевого обучения</th>
            <td>N/A</td>
        </tr>
        <tr>
            <th>Изучаемые языки</th>
            <td>N/A</td>
        </tr>
        <tr>
            <th>Средний балл</th>
            <td>N/A</td>
        </tr>
        <tr>
            <th>Дата начала обучения</th>
            <td>N/A</td>
        </tr>
        <tr>
            <th>Год выпуска</th>
            <td>N/A</td>
        </tr>
    </table>
</div>