import {
  Directive,
  Input,
  HostListener,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';

import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Directive({
  selector: '[afterValueChanged]'
})
export class AfterValueChangedDirective implements OnDestroy {
  @Input() public valueChangeDelay = 300;
  @Output() public afterValueChanged: EventEmitter<unknown> = new EventEmitter<unknown>();

  private stream: Subject<unknown> = new Subject<unknown>();
  private subscription: Subscription;

  constructor() {
    this.subscription = this.stream
      .pipe(debounceTime(this.valueChangeDelay))
      .subscribe((value: unknown) => this.afterValueChanged.next(value));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('valueChange', ['$event'])
  public onValueChange(value: unknown) {
    this.stream.next(value);
  }
}
