import {Injectable} from "@angular/core";
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {handleError} from '../../helpers/errorHandle-helper';
import {
  ApplicationConstructorSettings
} from '../../models/mfc/applicationConstructor/application-constructor-settings.model';
import {Property} from '../../models/mfc/applicationConstructor/tabs-settings.model';


@Injectable({
  providedIn: 'root'
})
export class ApplicationConstructorService {
  private baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.applicationConstructor}/`;

  constructor(private http: HttpClient) { }

  //TODO: временно
  private mapData(data: any): ApplicationConstructorSettings {
    const tabs = data.tabs.map((tab: any) => ({...tab, externalId: tab.id}));
    tabs.forEach((tab: any) =>
      tab.groupProperties.forEach((group: any) => {
        group.externalId = group.id;
        group.standardProperties = group.properties.map((property: any) => this.mapProperty(property));
        group.additionalProperties = group.additionalProperties.map((property: any) => this.mapProperty(property));
      }));
    return {...data, tabs: tabs};
  }

  private mapProperty(property: any): Property {
    const dictProperty = property.dictProperty;
    const prop = {
      ...property,
      externalId: property.id,
      name: dictProperty.name,
      propertyTypeEnum: dictProperty.propertyTypeEnum,
      dictPropertyEnum: dictProperty.dictPropertyEnum
    };
    if (dictProperty.dict?.dictValues) prop.dict = dictProperty.dict?.dictValues;
    return prop;
  }


  public getApplicationConstructor(typeId: string): Observable<ApplicationConstructorSettings> {
    return this.http.get<ApplicationConstructorSettings>(`${this.baseUrl}${typeId}/`)
      .pipe(map((data) => this.mapData(data)), catchError(handleError));
  }
}
