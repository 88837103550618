<h1>Трудовая деятельность</h1>

<div class="grid-container">
  <div *ngIf="!editMode">
    <button class="add-button" kendoButton (click)="moveToInfo()" themeColor="success">
      Добавить
      <span class="k-icon k-i-plus ml-8"></span>
    </button>
  </div>
  <kendo-grid
    [kendoGridBinding]="employments"
    [loading]="loading"
    (remove)="removeHandler($event)"
    (cellClick)="editHandler($event)"
    [sortable]="true"
    [sort]="[{ field: 'dateStart', dir: 'desc' }]"
  >
    <kendo-grid-column field="organization"
                       title="Наименование организации работодателя"
                       headerClass="gridHeader dictionaries"
                       [width]="300">
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="align-cells">
                {{ nameOrganization(dataItem.dictOrganizationId) }}
            </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="employerPost"
                       title="Должность"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="align-cells">
                {{ nameEmployerPost(dataItem.dictEmployerPostId) }}
            </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dateStart"
                       title="Дата трудоустройства"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="align-cells">{{ toLocalDatetime(dataItem.dateStart) }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dateFinish"
                       title="Дата увольнения"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="align-cells">{{ toLocalDatetime(dataItem.dateFinish) }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isCurrent"
                       editor="boolean"
                       title="Текущее место работы"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="align-cells">
          {{ dataItem.isCurrent ? 'Да' : 'Нет' }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries"
                       title="Подтверждающий документ"
                       field="scanFile">
      <ng-template kendoGridCellTemplate let-dataItem>
          <span class="align-cells">
            <span class="k-icon k-i-file pointer" (click)="downloadFile(dataItem.scanFile.id)">
            </span>
          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="70">
      <ng-template kendoGridCellTemplate>
        <span class="align-cells">
          <button kendoGridRemoveCommand
                  icon="trash"
                  fillMode="flat"
                  size="small"
                  rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>

  </kendo-grid>

</div>
<div kendoDialogContainer></div>
