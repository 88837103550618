import {EventEmitter, Injectable, Output} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {Employment} from "../../models/employment/employment.model";

@Injectable({
  providedIn: 'root'
})
export class EmploymentService {
  baseUrl = `${environment.apiEndpoint}${environment.employment.apiPaths.employment}`;

  constructor(private http: HttpClient) { }

  progress?: number;
  message?: string;

  public getEmployments(studentId: string) : Observable<Employment[]> {
    return this.http.get<Employment[]>(`${this.baseUrl}/${studentId}`)
      .pipe(catchError(this.handleError));
  }

  public getEmploymentById(id: string) : Observable<Employment> {
    return this.http.get<Employment>(`${this.baseUrl}/getEmploymentById/${id}`)
      .pipe(catchError(this.handleError));
  }

  public addEmployment(data: FormData) : Observable<Employment> {
    return this.http.post<Employment>(this.baseUrl, data)
      .pipe(catchError(this.handleError));
  }

  public updateEmployment(id: string, data: FormData) : Observable<Employment> {
    return this.http.put<Employment>(`${this.baseUrl}/${id}`, data)
      .pipe(catchError(this.handleError));
  }

  public deleteEmployment(id: string) : Observable<boolean> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`)
      .pipe(catchError(this.handleError));
  }

  public getEmploymentFile(employmentId: string) {
    return this.http.get(`${this.baseUrl}/downloadfile/${employmentId}`, {responseType: 'blob'})
      .pipe(catchError(this.handleError));
  }

  public handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
