import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {NotificationsService} from '../Notifications/notifications.service';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ApplicationCategoriesTree, ApplicationCategoryTree} from '../../models/mfc/application-categories-tree.model';


@Injectable({
  providedIn: 'root'
})
export class ApplicationTypeSelectService {
  private readonly baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.student}/GetCategoriesAndApplicationTypes/`;

  constructor(
    private http: HttpClient,
    private notificationService: NotificationsService
  ) { }

  getCategoriesTree(): Observable<ApplicationCategoryTree[]> {
    return this.http.get<ApplicationCategoriesTree>(this.baseUrl)
      .pipe(
        tap((response) => {
          if (!response || !response.categories.length) {
            this.notificationService.showError('Не удалось найти доступные категории и типы заявок');
          }
        }),
        map((d) => d.categories)
      );
  }
}
