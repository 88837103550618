<div class="container">
  <div class="content" *ngIf="!simpleInfoVisible && !abortVisible">
    <div class="contentRow">
      <p class="contentRowName">{{regulation?.regulationName}}</p>
      <button kendoButton icon="download"
              (click)="getRegulationFile(regulation?.regulationFileDto)" size="small">
        Скачать
      </button>
    </div>

    <div class="contentRow">
      <p class="contentRowText">Нажимая кнопку «Присоединиться к Положению», Вы принимаете изложенные в настоящей оферте условия и акцептуете настоящую оферту.</p>
    </div>

    <div class="contentRow buttons">
      <button kendoButton
              class="btn cancel-button"
              size="small"
              themecolor="dark"
              (click)="onAbort()"
      >
        Отказаться от присоединения к Положению
      </button>
      <button kendoButton
              class="btn"
              size="small"
              themeColor="primary"
              (click)="joinStudPersonToSimpleRegulation()"
      >
        Присоединиться к Положению
      </button>
    </div>
  </div>
  <div class="content" *ngIf="simpleInfoVisible">
    <div *ngIf="this.regulation?.notificationText" class="contentRow">
      <p class="contentRowText">{{this.regulation?.notificationText}}</p>
    </div>

    <div class="contentRow buttons">
      <button kendoButton
              class="btn"
              size="small"
              themeColor="primary"
              (click)="onConfirmation()"
      >
        Ознакомлен
      </button>
    </div>
  </div>
  <div class="content" *ngIf="abortVisible">
    <div class="contentRow">
      <p class="contentRowName">В случае отказа от присоединения к Положению работа в информационной системе будет невозможна! Для продолжения работы необходимо присоединиться к Положению.</p>
    </div>

    <div class="contentRow buttons">
      <button kendoButton
              class="btn cancel-button"
              size="small"
              themecolor="dark"
              (click)="onCancel()"
      >
        Отмена
      </button>
      <button kendoButton
              class="btn"
              size="small"
              themeColor="primary"
              (click)="logOut()"
      >
        Отказаться
      </button>
    </div>
  </div>
</div>
