import {Component, OnInit} from "@angular/core";
import {EmailService} from "../../../services/contacts/email.service";
import {PhoneService} from "../../../services/contacts/phone.service";
import {DictPhoneTypesService} from "../../../services/contacts/phoneTypes.service";
import {DictEmailTypesService} from "../../../services/contacts/emailTypes.service";
import {NotificationsService} from "../../../services/Notifications/notifications.service";
import {DictPhoneType} from "../../../models/profile/dictionaries/dictPhoneType.model";
import {DictEmailType} from "../../../models/profile/dictionaries/dictEmailType.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ContactTypeEnum} from "../../../models/profile/enums/contacttype.enum";
import {LKStudent} from "../../../models/profile/lkstudent.model";
import {LKStudPerson} from "../../../models/profile/lkStudPerson.model";
import {Email} from "../../../models/profile/contacts/emails.model";
import {LKStudentService} from "../../../services/LKStudent/lkstudent.service";
import {Phone} from "../../../models/profile/contacts/phones.model";
import {SocialNetwork} from "../../../models/profile/contacts/socialnetworks.model";
import {DisplaySystemSettingEnum} from "../../../models/home/enums/display-system-setting.enum";
import {DisplaySystemSettingService} from "../../../services/LKStudent/display-system-setting.service";
import {PhoneType} from "../../../models/enums/phoneTypeEnumList.enum";
import {SocialNetworkType} from "../../../models/profile/dictionaries/socialNetworkType.model";
import {DictSocialNetworkService} from "../../../services/contacts/dictSocialNetwork.service";
import {SocialNetworkService} from "../../../services/contacts/socialNetwork.service";
import {finalize} from "rxjs";

@Component({
  selector: "add-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"]
})
export class ContactComponent implements OnInit {

  public tooltip = "На основную почту будут приходить электронные письма, а также почта будет использоваться для восстановления доступа к учетной записи.";

  public contactTypeName: string | null = null;
  public contactId: string | null = null;

  public phoneType = PhoneType;

  public phoneNumberMask = '+9 (999) 000-00-00';

  public contactType: number | null = null;
  public contactTypeEnum = ContactTypeEnum;
  public systemEnum = DisplaySystemSettingEnum;

  public isEditingMode: boolean = false;
  public isMain: boolean = false;
  public currentActive: boolean = false;

  public phoneMode: boolean = false;
  public phoneCityMode: boolean = false;
  public emailMode: boolean = false;
  public socialMode: boolean = false;

  public phoneTypes: DictPhoneType[] = [];
  public emailTypes: DictEmailType[] = [];
  public socialNetworksTypes: SocialNetworkType[] = [];

  public isCodeSent: boolean = false;
  public isCodeValid: boolean = false;

  public isWaitingServerResponse: boolean = false;

  public emailForm: FormGroup = this.getEmailFormGroup();
  public socialForm: FormGroup = this.getSocialFormGroup();
  public phoneForm: FormGroup = this.getPhoneFormGroup();
  public phoneCityForm: FormGroup = this.getPhoneFormGroup();

  public dictContactTypes: Array<{ dictContactTypeName: string; type: number }> = [
    !this.systemSettingIsHidden(this.systemEnum.mobile_phone) ? {dictContactTypeName: 'Мобильный телефон', type: ContactTypeEnum.mobilePhone} : null,
    !this.systemSettingIsHidden(this.systemEnum.city_phone) ? {dictContactTypeName: 'Городской телефон', type: ContactTypeEnum.cityPhone} : null,
    !this.systemSettingIsHidden(this.systemEnum.email) ? {dictContactTypeName: 'E-mail', type: ContactTypeEnum.email} : null,
    !this.systemSettingIsHidden(this.systemEnum.socialnetwork) ? {dictContactTypeName: 'Соц. сеть', type: ContactTypeEnum.social} : null,
  ].filter((item): item is { dictContactTypeName: string; type: number } => item !== null);

  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "-",
    studPersonId: "",
    studPerson: this.studPerson
  }

  constructor(
    private studentService: LKStudentService,
    private emailService: EmailService,
    private phoneService: PhoneService,
    private socialNetworksService: SocialNetworkService,
    private dictPhoneTypesService: DictPhoneTypesService,
    private dictEmailTypesService: DictEmailTypesService,
    private notificationService: NotificationsService,
    private router: Router,
    private route: ActivatedRoute,
    private displaySystemSettingService: DisplaySystemSettingService,
    private dictSocialNetworkService: DictSocialNetworkService,
  ) { }

  async ngOnInit() {
    await this.getCurrentStudent();
  }

  public getParamsFromRoute() {
    this.route.params.subscribe(params => {
      this.contactTypeName = params['contactTypeName'] || null;
      this.contactId = params['contactId'] || null;

      if (this.contactTypeName && this.contactId) {
        this.isEditingMode = true;

        switch (this.contactTypeName) {
          case 'email':
            this.getEmailById(this.contactId);
            this.onContactTypeChange(ContactTypeEnum.email);
            break;
          case 'phone':
            this.getPhoneById(this.contactId);
            this.onContactTypeChange(ContactTypeEnum.mobilePhone);
            break;
          case 'cityPhone':
            this.getCityPhoneById(this.contactId);
            this.onContactTypeChange(ContactTypeEnum.cityPhone);
            break;
          case 'social':
            this.getSocialNetworkById(this.contactId);
            this.onContactTypeChange(ContactTypeEnum.social);
            break;
        }
      }
    });
  }

  private getPhoneById(id: string) {
    this.phoneService.getPhoneById(this.studentModel.externalId!, id)
      .subscribe(response => {
        this.phoneForm = new FormGroup({
          phoneId: new FormControl<string|null>(response.phoneId!),
          studPersonId: new FormControl<string|null>(response.studPersonId),
          phoneNumber: new FormControl<string|null>(this.phoneFormat(response.phoneNumber)),
          dictPhoneTypeId: new FormControl<string|null>(response.dictPhoneTypeId),
          isMain: new FormControl<boolean>(response.isMain),
        });

        this.isMain = response.isMain;
        this.currentActive = response.isMain;
      })
  }

  private getCityPhoneById(id: string) {
    this.phoneService.getPhoneById(this.studentModel.externalId!, id)
      .subscribe(response => {
        this.phoneCityForm = new FormGroup({
          phoneId: new FormControl<string|null>(response.phoneId!),
          studPersonId: new FormControl<string|null>(response.studPersonId),
          phoneNumber: new FormControl<string|null>(this.phoneFormat(response.phoneNumber)),
          dictPhoneTypeId: new FormControl<string|null>(response.dictPhoneTypeId),
        });
      })
  }

  private getEmailById(id: string) {
    this.emailService.getById(this.studentModel.externalId!, id)
      .subscribe(response => {
        this.emailForm = new FormGroup({
          emailId: new FormControl<string|null>(response.emailId!),
          studPersonId: new FormControl<string|null>(response.studPersonId),
          email: new FormControl<string|null>(response.email),
          dictEmailTypeId: new FormControl<string|null>(response.dictEmailTypeId),
          isMain: new FormControl<boolean>(response.isMain),
          code: new FormControl<string|null>(''),
        });

        this.isMain = response.isMain;
        this.currentActive = response.isMain;
      })
  }

  private getSocialNetworkById(id: string) {
    this.socialNetworksService.getSocialNetworkById(this.studentModel.externalId!, id)
      .subscribe(response => {
        this.socialForm = new FormGroup({
          id: new FormControl<string|null>(response.id),
          studPersonId: new FormControl<string|null>(response.studPersonId),
          socialNetwork: new FormControl<string|null>(response.socialNetwork),
          dictSocialNetworkId: new FormControl<string|null>(response.dictSocialNetworkId),
        });
      })
  }

  public setDefaultMainOptions() {
    this.isCodeValid = false;
    this.isCodeSent = false;
  }

  public phoneFormat(phoneNumber: string): string {
    if (phoneNumber !== null) {
      let phoneFormatted = phoneNumber.replace(/[-()+\s]/g, "");

      if (phoneFormatted.length === 10) {
        phoneFormatted = "7" + phoneFormatted;
      }

      if (phoneFormatted.length === 11) {
        phoneFormatted = phoneFormatted.substring(1);
        phoneFormatted = phoneFormatted.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "+7 ($1) $2-$3-$4");
      }

      return phoneFormatted;
    }

    return phoneNumber;
  }

  public getDisplaySystemSettings() {
    this.displaySystemSettingService.GetDisplaySettings()
      .subscribe(
        response => {
          this.displaySystemSettingService.displaySettings$.next(response);
        }
      );
  }

  public systemSettingIsHidden(field: number): boolean {
    let isHidden = false;
    return this.displaySystemSettingService.isHidden(field) || isHidden;
  }

  public getAllPhoneTypes() {
    this.dictPhoneTypesService.getAllPhoneTypes()
      .subscribe(response => {
        this.phoneTypes = response;
      })
  }

  public getAllDictSocialNetworks() {
    this.dictSocialNetworkService.getAllSocialNetworks()
      .subscribe(
        response => {
          this.socialNetworksTypes = response;
        }
      );
  }

  public getAllEmailTypes() {
    this.dictEmailTypesService.getAllEmailTypes()
      .subscribe(response => {
        this.emailTypes = response;
      })
  }

  public async getCurrentStudent() {
    const changeStudent = Number(localStorage.getItem('changeStudent'));
    this.studentService.getCurrentStudent()
      .subscribe(
        async response => {
          this.students = response;
          if(changeStudent) {
            this.studentModel = this.students[changeStudent-1];
          }
          else {
            this.studentModel = this.students[0];
          }

          this.getDisplaySystemSettings();
          this.getParamsFromRoute();
          this.getAllPhoneTypes();
          this.getAllDictSocialNetworks();
          this.getAllEmailTypes();
        }
      );
  }

  public onContactTypeChange(type: number): void {
    this.contactType = type;
    this.socialMode = false;
    this.emailMode = false;
    this.phoneMode = false;
    this.phoneCityMode = false;
    this.emailForm = this.getEmailFormGroup();
    this.socialForm = this.getSocialFormGroup();
    this.phoneForm = this.getPhoneFormGroup();
    this.phoneCityForm = this.getPhoneFormGroup();
    switch (type) {
      case 1:
        this.phoneMode = true;
        break;
      case 2:
        this.emailMode = true;
        break;
      case 3:
        this.socialMode = true;
        break;
      case 4:
        this.phoneCityMode = true;
        break;
    }
  }

  public sendCodeToEmail() {
    this.isWaitingServerResponse = true;

    this.emailService.sendActivateCode(this.emailForm.value.email).pipe(
      finalize(() => this.isWaitingServerResponse = false)
    ).subscribe({
      next: () => {
        this.isCodeSent = true;
      },
      error: err => {
        this.notificationService.showError(err);
      }
    });
  }

  public checkValidCode() {
    this.isWaitingServerResponse = true;

    this.emailService.checkActivationCode(this.emailForm.value.email, this.emailForm.value.code).pipe(
      finalize(() => this.isWaitingServerResponse = false)
    ).subscribe({
      next: () => {
        this.isCodeValid = true;
      },
      error: err => {
        this.notificationService.showError(err);
      }
    });
  }

  public toProfile() {
    this.router.navigate(['/home']);
  }

  public onDeleteContact() {
    switch (this.contactType) {
      case this.contactTypeEnum.email:
        this.deleteEmail();
        break;
      case this.contactTypeEnum.mobilePhone:
        this.deletePhone();
        break;
      case this.contactTypeEnum.cityPhone:
        this.deletePhone();
        break;
      case this.contactTypeEnum.social:
        this.deleteSocial();
        break;
    }
  }

  private deleteEmail() {
    this.emailService.deleteEmail(this.studentModel.externalId, this.emailForm.value.emailId).subscribe({
      next: () => {
        this.notificationService.showSuccess("Контакт успешно удален");
        this.toProfile();
      },
      error: err => {
        this.notificationService.showError(err);
      }
    });
  }

  private deletePhone() {
    this.phoneService.deletePhone(this.studentModel.externalId, this.phoneForm.value.phoneId || this.phoneCityForm.value.phoneId).subscribe({
      next: () => {
        this.notificationService.showSuccess("Контакт успешно удален");
        this.toProfile();
      },
      error: err => {
        this.notificationService.showError(err);
      }
    });
  }

  private deleteSocial() {
    this.socialNetworksService.deleteSocialNetwork(this.studentModel.externalId, this.socialForm.value.id).subscribe({
      next: () => {
        this.notificationService.showSuccess("Контакт успешно удален");
        this.toProfile();
      },
      error: err => {
        this.notificationService.showError(err);
      }
    });
  }

  public onSaveContact(e: PointerEvent) {
    this.isWaitingServerResponse = true;

    e.preventDefault();
    switch (this.contactType) {
      case this.contactTypeEnum.email:
        this.saveEmail();
        break;
      case this.contactTypeEnum.mobilePhone:
        this.savePhone();
        break;
      case this.contactTypeEnum.cityPhone:
        this.savePhone();
        break;
      case this.contactTypeEnum.social:
        this.saveSocial();
        break;
    }
  }

  private saveEmail() {
    const email: Email = {
      emailId: this.emailForm.value.emailId,
      studPersonId: this.emailForm.value.studPersonId,
      dictEmailTypeId: this.emailForm.value.dictEmailTypeId,
      email: this.emailForm.value.email,
      isMain: this.emailForm.value.isMain
    }

    if (this.isEditingMode) {
      this.emailService.updateEmail(this.emailForm.value.emailId, this.studentModel.externalId, email).subscribe({
        next: () => {
          this.notificationService.showSuccess("Контакт успешно изменен");
          this.toProfile();
        },
        error: err => {
          this.notificationService.showError(err);
          this.isWaitingServerResponse = false;
        }
      });
    }
    else {
      this.emailService.addEmail(this.studentModel.externalId, email).subscribe({
        next: () => {
          this.notificationService.showSuccess("Контакт успешно добавлен");
          this.toProfile();
        },
        error: err => {
          this.notificationService.showError(err);
          this.isWaitingServerResponse = false;
        }
      });
    }
  }

  private savePhone() {
    const phone: Phone = {
      phoneId: this.phoneForm.value.phoneId || this.phoneCityForm.value.phoneId,
      studPersonId: this.phoneForm.value.studPersonId,
      dictPhoneTypeId: this.phoneForm.value.dictPhoneTypeId || this.phoneType.city,
      phoneNumber: this.phoneForm.value.phoneNumber || this.phoneCityForm.value.phoneNumber,
      isMain: this.phoneForm.value.isMain || false
    }

    if (this.isEditingMode) {
      this.phoneService.updatePhone(this.studentModel.externalId, phone.phoneId!, phone).subscribe({
        next: () => {
          this.notificationService.showSuccess("Контакт успешно изменен");
          this.toProfile();
        },
        error: err => {
          this.notificationService.showError(err);
          this.isWaitingServerResponse = false;
        }
      });
    }
    else {
      this.phoneService.addPhone(this.studentModel.externalId, phone).subscribe({
        next: () => {
          this.notificationService.showSuccess("Контакт успешно добавлен");
          this.toProfile();
        },
        error: err => {
          this.notificationService.showError(err);
          this.isWaitingServerResponse = false;
        }
      });
    }
  }

  private saveSocial() {
    const socialNetwork: SocialNetwork = {
      id: this.socialForm.value.id,
      socialNetwork: this.socialForm.value.socialNetwork,
      studPersonId: this.socialForm.value.studPersonId,
      dictSocialNetworkId: this.socialForm.value.dictSocialNetworkId
    }

    if (!this.checkURLFormat(socialNetwork.socialNetwork)) {
      this.notificationService.showError('Неверный формат ссылки');
      return;
    }

    if (this.isEditingMode) {
      this.socialNetworksService.updateSocialNetwork(this.studentModel.externalId, this.socialForm.value.id, socialNetwork).subscribe({
        next: () => {
          this.notificationService.showSuccess("Контакт успешно изменен");
          this.toProfile();
        },
        error: err => {
          this.notificationService.showError(err);
          this.isWaitingServerResponse = false;
        }
      });
    }
    else {
      this.socialNetworksService.addSocialNetwork(this.studentModel.externalId, socialNetwork).subscribe({
        next: () => {
          this.notificationService.showSuccess("Контакт успешно добавлен");
          this.toProfile();
        },
        error: err => {
          this.notificationService.showError(err);
          this.isWaitingServerResponse = false;
        }
      });
    }
  }

  public isFormInvalid(): boolean {
    if (this.emailMode) {
      if (this.emailForm.invalid) return true;

      if (!this.systemSettingIsHidden(this.systemEnum.student_can_edit_deanery_contacts)) {
        return this.isMain ? !this.isCodeValid : false;
      }

      return false;
    } else if (this.socialMode) {
      if (this.socialForm.invalid) return true;

      return !this.checkURLFormat(this.socialForm.value.socialNetwork);
    } else if (this.phoneMode) {
      return this.phoneForm.invalid;
    } else if (this.phoneCityMode) {
      return this.phoneCityForm.invalid;
    }
    return true;
  }

  public checkURLFormat(value: string): boolean {
    const pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})[\/\w .-]*\/?$/;
    return pattern.test(value);
  }

  public canBeDeleted(): boolean {
    return this.isEditingMode && (this.emailMode ? this.isMain === false : true);
  }

  private getEmailFormGroup() {
    return new FormGroup({
      emailId: new FormControl<string|null>(''),
      studPersonId: new FormControl<string|null>(this.studentModel?.studPersonId),
      dictEmailTypeId: new FormControl<string|null>('', Validators.required),
      email: new FormControl<string|null>('', Validators.required),
      isMain: new FormControl<boolean>(false),
      code: new FormControl<string|null>(''),
    });
  }

  private getSocialFormGroup() {
    return new FormGroup({
      id: new FormControl<string|null>(''),
      studPersonId: new FormControl<string|null>(this.studentModel?.studPersonId),
      socialNetwork: new FormControl<string|null>('', [
        Validators.required,
        Validators.pattern('(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
      ]),
      dictSocialNetworkId: new FormControl<string|null>('', Validators.required),
    });
  }

  private getPhoneFormGroup() {
    return new FormGroup({
      phoneId: new FormControl<string|null>(''),
      studPersonId: new FormControl<string|null>(this.studentModel?.studPersonId),
      phoneNumber: new FormControl<string|null>('', Validators.required),
      dictPhoneTypeId: new FormControl<string|null>(''),
      isMain: new FormControl<boolean>(false),
    });
  }
}
