<div class="container" [ngSwitch]="approvalSettings.length > 0">
  <ng-container *ngSwitchCase="true">
    <div class="document" *ngFor="let group of documentGroups">
      <h4>{{group.statusName}}</h4>
      <div class="document__item" *ngFor="let approvalSettings of group.items">
        <ng-container [ngTemplateOutlet]="documentsTemp" [ngTemplateOutletContext]="{approvalSettings: approvalSettings}">
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="false">
    <div class="document__item" *ngFor="let doc of documents">
      <div class="field">
        <h6 class="document-name">{{doc.name}}</h6>
        <span>
          <button (click)="downloadReadyDocument(doc)" kendoButton icon="download">Скачать</button>
<!--          <h6 class="hint">Печатная форма справки с введенными и сохраненными в форме заявки данными.</h6>-->
        </span>
      </div>
    </div>
  </ng-container>
</div>

<ng-template [typedTemplate]="documentTemplate" #documentsTemp let-approvalSettings="approvalSettings">
  <div class="field" *ngIf="approvalSettings.document">
    <h6 class="document-name">{{approvalSettings.document.name}}</h6>
    <span>
      <button (click)="downloadDocument(approvalSettings)" kendoButton icon="download">Скачать</button>
      <h6 *ngIf="!approvalSettings.completedApprovalStep" class="hint">Печатная форма справки с введенными и сохраненными в форме заявки данными.</h6>
    </span>
  </div>

  <ng-container *ngIf="approvalSettings.isCurrentStage || approvalSettings.completedApprovalStep"
                [ngTemplateOutletContext]="{approvalSettings: approvalSettings}"
                [ngTemplateOutlet]="signatureTemp">
  </ng-container>
</ng-template>

<ng-template [typedTemplate]="signatureTemplate" #signatureTemp let-approvalSettings="approvalSettings">

  <ng-container *ngIf="approvalSettings.approvalType === ApplicationApprovalTypeEnum.SigningOfAnEnhancedElectronicSignature && approvalSettings.completedApprovalStep">
    <div class="field">
      <h6 class="document-name">Файл с открепленной электронной подписью</h6>
      <button (click)="downloadDocumentSign(approvalSettings.document)" kendoButton icon="download">Скачать</button>
    </div>
    <div class="field">
      <h6 class="document-name">{{approvalSettings.document?.name}} со штампом об эл.подписи и эл. печати</h6>
      <button (click)="downloadDocumentByPdf(approvalSettings.document)" kendoButton icon="download">Скачать</button>
    </div>
  </ng-container>

  <h6>Статус подписи</h6>
  <div class="signature">
    <span class="field center">
      <h6>{{signatoryRolesMap.get(approvalSettings.dictSignatoryRoleId) ?? rolesMap.get(approvalSettings.dictRoleId)}}</h6>
      <span class="signed-item" *ngIf="approvalSettings.completedApprovalStep; else text">
        <h6 class="status">{{approvalSettings.approvalType ? signingsMap.get(approvalSettings.approvalType) : ''}}</h6>
        <p>{{approvalSettings.completedApprovalStep?.personName}}, подписано, {{approvalSettings.signDate | date:'dd.MM.yyyy HH:mm'}}</p>
        <p>Действителен до {{approvalSettings.expirationDate | date:'dd.MM.yyyy HH:mm'}}</p>
      </span>
    </span>
    <ng-template #text>
      <span class="signed-item">
        <h6 class="status">{{approvalSettings.statusName}}</h6>
      </span>
    </ng-template>
  </div>
</ng-template>
