<div class="grid-layout-container container">
    <h1 class="title"><a href="https://library.nntu.ru/megapro/web" target="_blank">Электронный каталог научно-технической библиотеки НГТУ</a></h1>

    <h2 style="font-weight: 600;">Издания электронных библиотечных систем (ЭБС):</h2>
    <div class="messages">Индивидуальный авторизованный доступ из сети "Интернет", в том числе с мобильных устройств, предварительно необходимо зарегистрировать свой личный кабинет, находясь внутри локальной сети вуза</div>

    <h1 class="link"> <a href="http://www.studentlibrary.ru" target="_blank"> Консультант студента </a></h1>
    <h1 class="link"> <a href="https://lanbook.com" target="_blank"> ИД "Лань" </a></h1>
    <h1 class="link"> <a href="https://e.lanbook.com" target="_blank"> ЭБС "Лань" </a></h1>
    <h1 class="link"> <a href="https://urait.ru" target="_blank"> ЭБС "Юрайт" (раздел "Легендарные книги") </a></h1>
</div>
