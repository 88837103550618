<h5 class="achievements-title">Достижения</h5>
<div *ngIf="!editMode">
  <button
    kendoButton
    class="achievements-title"
    themeColor="success"
    iconClass="k-icon k-i-plus"
    (click)="addAchievement()"
  >
    Добавить
  </button>

  <h6 class="noAchievementHeader" *ngIf="achievements.length == 0">
    Нет загруженных достижений
  </h6>
  <kendo-listview
    containerClass="k-d-flex k-flex-col k-flex-nowrap"
    style="border-style: none"
    *ngIf="achievements.length > 0"
    [data]="achievements"
    [loading]="loading"
  >
    <ng-template kendoListViewLoaderTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
      <h4 class="title-gray-color">
        {{ displayDirectionActivityName(dataItem.directionActivityId) }}
      </h4>
      <kendo-grid
        class="portfolio-contingent-grid"
        scrollable="none"
        [data]="dataItem.data"
        (remove)="removeAchievement($event)"
        (edit)="editAchievement($event)"
      >
        <kendo-grid-column
          headerClass="grid-header"
          field="name"
          title="Название работы/мероприятия/достижения"
          [headerStyle]="{ 'text-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="align-cells">{{ dataItem.name }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          headerClass="grid-header"
          field="updateAt"
          title="Последнее изменение"
          [headerStyle]="{ 'text-align': 'center' }"
          [width]="250"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="align-cells">
              {{
              dataItem?.updateAt == null
                ? ''
                : (dataItem.updateAt | date: 'dd.MM.yy HH:mm')
              }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          headerClass="grid-header"
          field="review"
          title="Сканированная копия"
          [width]="250"
          [headerStyle]="{ 'text-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="k-text-center">
              <span
                style="cursor: pointer; margin: 0 auto"
                class="k-icon k-i-file"
                *ngFor="let file of dataItem.files"
                (click)="downloadFile(file)"
              >
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          title=""
          [headerStyle]="{ 'text-align': 'center' }"
          [width]="140"
        >
          <ng-template kendoGridCellTemplate>
            <button
              kendoGridEditCommand
              themeColor="secondary"
              icon="edit"
              fillMode="flat"
              size="none"
              rounded="full"
            ></button>
            <button
              kendoGridRemoveCommand
              themeColor="secondary"
              icon="delete"
              fillMode="flat"
              size="none"
              rounded="full"
            ></button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </ng-template>
  </kendo-listview>
</div>
<div class="container" *ngIf="editMode">
  <form novalidate class="k-form" [formGroup]="editForm">
    <kendo-formfield>
      <div class="portfolio-field">
        <h2 class="portfolio-field__title">Название деятельности</h2>
        <kendo-dropdownlist
          textField="name"
          valueField="id"
          required
          formControlName="directionActivityId"
          [data]="directionActivitiesByFilter"
          [valuePrimitive]="true"
        >
        </kendo-dropdownlist>
      </div>
      <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <div class="portfolio-field">
        <h2 class="portfolio-field__title">
          Название работы/мероприятия/достижения
        </h2>
        <kendo-textbox
          formControlName="name"
          required
          [maxlength]="250"
        ></kendo-textbox>
      </div>
      <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <div class="portfolio-field">
        <h2 class="portfolio-field__title">Сканированная копия</h2>
        <div class="portfolio-field__file">
          <kendo-fileselect
            [restrictions]="restrictions"
            [multiple]="false"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="fileUploads"
            (select)="fileSelected()"
            (remove)="fileRemoved()"
          >
            <kendo-fileselect-messages
              invalidFileExtension="Недопустимый формат файла. Используйте только .pdf файлы"
              [invalidMaxFileSize]="maxFileSizeMessage"
            >
            </kendo-fileselect-messages>
          </kendo-fileselect>
          <span class="k-display-flex link"
                *ngFor='let file of fileUploadedList'
                (click)="downloadFile(file)">
            <span class="k-icon k-i-file"></span>
            <h2 class="link">{{file.name}}</h2>
          </span>
        </div>
      </div>
    </kendo-formfield>
    <!--div *ngIf="checked" class="condition-checkbox">
      <input type="checkbox" formControlName="meetsScholarshipRequirement" #meetsScholarshipRequirement />
      <h3 class="condition-title">Соответствует критерию из стипендиального положения</h3>
    </div-->
    <h2 class="warning" *ngIf="!fileUploaded">
      * Загрузите скан.копию для добавления нового достижения
    </h2>
    <div class="buttons">
      <button kendoButton (click)="closeEditForm()">Отмена</button>
      <button
        kendoButton
        themeColor="primary"
        class="save-button"
        *ngIf="fileUploaded && editForm.valid"
        (click)="saveAchievement()"
      >
        Сохранить
      </button>
    </div>
  </form>
</div>
<div kendoDialogContainer></div>
