import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {DisciplineTableByCycleType, DisciplineTableBySemester} from "../../models/mfc/applicationForm/discipline-table.model";
import {catchError} from "rxjs";
import {NotificationsService} from "../Notifications/notifications.service";
import {handleError} from "../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class DisciplineTableService {
  public applicationId? = '';

  private get baseURL() {
    return `${environment.apiEndpoint}${environment.mfc.apiPaths.application}/${this.applicationId}/discipline-table`;
  }

  constructor(private http: HttpClient, private notificationsService: NotificationsService) {}

  public getRowsByCycleType() {
    return this.http.get<DisciplineTableByCycleType[]>(`${this.baseURL}/by-cycle-type/row`)
      .pipe(catchError(err => {
        this.notificationsService.showError(err.error);
        return handleError(err);
      }));
  }

  public getRowByCycleType(rowId: string) {
    return this.http.get<DisciplineTableByCycleType>(`${this.baseURL}/by-cycle-type/row/${rowId}`)
      .pipe(catchError(err => {
        this.notificationsService.showError(err.error);
        return handleError(err);
      }));
  }

  public getRowsBySemester() {
    return this.http.get<DisciplineTableBySemester[]>(`${this.baseURL}/by-semester/row`)
      .pipe(catchError(err => {
        this.notificationsService.showError(err.error);
        return handleError(err)
      }));
  }

  public getRowBySemester(rowId: string) {
    return this.http.get<DisciplineTableBySemester>(`${this.baseURL}/by-semester/row/${rowId}`)
      .pipe(catchError(err => {
        this.notificationsService.showError(err.error);
        return handleError(err);
      }));
  }
}