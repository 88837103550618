import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {handleError} from "../../helpers/errorHandle-helper";
import {DictRegulationType} from "../../models/oferta/dicts/dictRegulationType.model";

@Injectable({
  providedIn: 'root'
})
export class DictRegulationTypeService {

  baseUrl = `${environment.apiEndpoint}${environment.oferta.apiPaths.dictRegulationType}`

  constructor(private http: HttpClient) { }

  getAllRegulationTypes(): Observable<DictRegulationType[]> {
    return this.http.get<DictRegulationType[]>(this.baseUrl);
  }
}
