import {Routes} from "@angular/router";
import {Type} from '@angular/core';
import {MFCHomeComponent} from "../components/mfc/home/mfc-home.component";
import {AuthGuard} from "../guards/authguard";
import {ApplicationFormHomeComponent} from "../components/mfc/applicationForm/home/application-form-home.component";
import {ApplicationFormTabComponent} from "../components/mfc/applicationForm/tab/application-form-tab.component";
import {ApplicationFormApprovalListComponent} from "../components/mfc/applicationForm/approvalList/approval-list.component";
import {ApplicationFormCommentsComponent} from "../components/mfc/applicationForm/comments/comments.component";
import {AppicationTypeSelectComponent} from '../components/mfc/applicationTypeSelect/application-type-select.component';
import {ApplicationFormDocumentsComponent} from "../components/mfc/applicationForm/documents/documents.component";
import {TypedTemplateDirective} from "../directives/typed-template.directive";
import {
  DateRangeFieldComponent
} from '../components/mfc/applicationForm/dynamicProperties/date-range/date-range-field.component';
import {DynamicPropertiesComponent} from "../components/mfc/applicationForm/dynamicProperties/dynamic-properties.component";
import {StudentTableComponent} from "../components/mfc/applicationForm/dynamicProperties/table/student/student-table.component";
import {CostTableComponent} from "../components/mfc/applicationForm/dynamicProperties/table/cost/cost-table.component";
import {DisciplineTableComponent} from "../components/mfc/applicationForm/discipline/discipline-table.component";
import {
  DisciplineTableFormComponent
} from "../components/mfc/applicationForm/discipline/form/discipline-table-form.component";

export const MfcRoutes: Routes = [
  {path: 'mfc', component: MFCHomeComponent, canActivate: [AuthGuard]},
  {path: 'mfc/applicationTypeSelect', component: AppicationTypeSelectComponent, canActivate: [AuthGuard]},
  {path: 'mfc/applicationForm', component: ApplicationFormHomeComponent, canActivate: [AuthGuard]},
  {path: 'mfc/applicationForm/:id', component: ApplicationFormHomeComponent, canActivate: [AuthGuard]},
  {path: 'mfc/applicationForm/:id/discipline/:disciplineid', component: DisciplineTableFormComponent, canActivate: [AuthGuard]},
];

export const MFCComponents: Type<unknown>[] = [
  MFCHomeComponent,
  AppicationTypeSelectComponent,
  ApplicationFormHomeComponent,
  ApplicationFormTabComponent,
  ApplicationFormApprovalListComponent,
  ApplicationFormCommentsComponent,
  ApplicationFormDocumentsComponent,
  DynamicPropertiesComponent,
  CostTableComponent,
  StudentTableComponent,
  TypedTemplateDirective,
  DateRangeFieldComponent,
  DisciplineTableComponent,
  DisciplineTableFormComponent
];
