export enum DictCycleType {
  // Дисциплины
  Disciplines = 1,
  // Практики
  Practices,
  // Научные исследования
  ScienceResearches,
  // Государственная итоговая аттестация
  Gia,
  // Факультативные дисциплины
  FacultyDisciplines,
  // Научный компонент
  ScienceComponent,
  // Образовательный компонент
  EducationComponent,
  // Курсовые работы (Локальный enum)
  CourseWork = 100
}

export const dictCycleTypes = [
  {id: DictCycleType.Disciplines, text: 'Дисциплины'},
  {id: DictCycleType.Practices, text: 'Практики'},
  {id: DictCycleType.ScienceResearches, text: 'Научные исследования'},
  {id: DictCycleType.Gia, text: 'Государственная итоговая аттестация'},
  {id: DictCycleType.CourseWork, text: 'Курсовые работы'},
  {id: DictCycleType.FacultyDisciplines, text: 'Факультативные дисциплины'},
  {id: DictCycleType.ScienceComponent, text: 'Научный компонент'},
  {id: DictCycleType.EducationComponent, text: 'Образовательный компонент'},
];