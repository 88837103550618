import {Component, Output, EventEmitter, Input, OnInit} from '@angular/core';


@Component({
  selector: 'date-range-field',
  templateUrl: './date-range-field.component.html',
  styleUrls: ['./date-range-field.component.scss'],
})
export class DateRangeFieldComponent implements OnInit {
  @Input() value?: {dateStart?: Date; dateEnd?: Date};
  @Input() required = false;
  @Input() editable = false;
  @Output() valueChange = new EventEmitter<{dateStart?: Date; dateEnd?: Date}|null>();
  protected startDate?: Date;
  protected endDate?: Date;

  ngOnInit() {
    if (this.value) {
      this.startDate = this.value.dateStart;
      this.endDate = this.value.dateEnd;
    }
  }

  protected get min(): Date|null {
    return this.startDate ?? null;
  }

  protected get max(): Date|null {
    return this.endDate ?? null;
  }

  protected onStartChange(value?: Date): void {
    this.startDate = value;
    this.valueChange.emit({dateStart: value, dateEnd: this.endDate});
  }

  protected onEndChange(value?: Date): void {
    this.endDate = value;
    this.valueChange.emit({dateStart: this.startDate, dateEnd: value});
  }
}
