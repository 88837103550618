import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {RemoveEvent} from "@progress/kendo-angular-grid";
import {StudentTableRow} from "../../../../../../models/mfc/applicationForm/tables.model";
import {FacultyService} from "../../../../../../services/mfc/dicts/faculty.service";
import {Faculty} from "../../../../../../models/mfc/dicts/faculty.model";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";

@Component({
  selector: 'mfc-student-table',
  templateUrl: './student-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentTableComponent implements OnInit {
  @Input() editable = false;
  @Input() data: StudentTableRow[] = [];

  @Output() value = new EventEmitter<StudentTableRow[]>();

  protected gridView: StudentTableRow[] = [];
  protected faculties: Faculty[] = [];
  protected readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  constructor(
    private facultyService: FacultyService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.data.length) {
      this.addRow();
    }
    this.gridView = this.data;
    this.getFaculties();
  }

  protected getFaculties() {
    this.facultyService.getFaculties().subscribe(response => {
      this.faculties = response;
      this.cdr.detectChanges();
    });
  }

  protected addRow() {
    this.data.push(new StudentTableRow());
    this.onValueChange();
  }

  protected removeRow({rowIndex}: RemoveEvent) {
    this.data.splice(rowIndex, 1);
    this.onValueChange();
  }

  protected onValueChange() {
    this.value.emit(this.data);
  }
}