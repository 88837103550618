<div class="container">
  <ng-container *ngIf="isSemester">
    <div class="field">
      <h3>Семестры</h3>
      <kendo-multiselect [data]="semesters"
                         [valuePrimitive]="true"
                         [(ngModel)]="editFormService.disciplineTableSemesters"
                         [disabled]="!editable"
                         (valueChange)="onSemesterChange($event)"
                         valueField="id"
                         textField="name">
      </kendo-multiselect>
    </div>
    <div *ngIf="studentPeriod" class="field">
      <h3>Выбранный период</h3>
      <h3>{{studentPeriod}}</h3>
    </div>
  </ng-container>

  <kendo-grid [data]="gridView"
              [height]="700"
              [rowHeight]="49"
              [skip]="state.skip"
              [pageSize]="state.take"
              (pageChange)="pageChange($event)"
              (cellClick)="onCellClick($event)"
              *ngIf="applicationId && gridView.data.length"
              scrollable="virtual"
              class="grid-data">

    <kendo-grid-column [width]="50" title="№">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <ng-container *ngIf="!dataItem.isGroup">
          {{dataItem.sortNumber}}
        </ng-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column class="left-align" field="name" title="Дисциплина">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
        <span [class]="{'group-header': dataItem.isGroup, 'total-field': dataItem.name === 'Итого'}">
          {{dataItem[column.field]}}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="200" field="laborIntensity" title="Зач. ед.">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
        <span *ngIf="isSemester ? !dataItem.isGroup : true" [class]="{semibold: dataItem.isGroup}">
          {{dataItem[column.field]}}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="200" field="hours" title="Общее количество часов">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
        <span *ngIf="isSemester ? !dataItem.isGroup : true" [class]="{semibold: dataItem.isGroup}">
          {{dataItem[column.field]}}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" field="dictMarkId" title="Итоговая оценка">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
        <ng-container *ngIf="!dataItem.isGroup">
          {{marksMap.get(dataItem[column.field])}}
        </ng-container>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
