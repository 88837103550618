export interface AchievementGet {
  id: string;
  name: string;
  studentId: string;
  directionActivityId: string;
  files: {
    id: string;
    name: string;
    updateAt: Date;
  }[];
}

export interface Achievement {
  achievementList: AchievementList[];
  totalMarks: TotalMarks[];
  achievementPeriod: {
    checkingEndDate: Date;
    checkingStartDate: Date;
    pretendentEndDate: Date;
    pretendentStartDate: Date;
    receiptionEndDate: Date;
    receiptionStartDate: Date;
    workCheckDate: Date;
  };
  directionActivityId: string;
  directionActivityName: string;
  hideAchievements?: boolean;
}

export interface AchievementList {
  id: string;
  studPersonId: string;
  name: string;
  comment?: string;
  dictAchievementStatusDate?: Date;
  dictAchievementStatusId?: string;
  dictAchievementStatusName?: string;
  dictWorkTypeId?: string;
  dictWorkTypeName?: string;
  directionActivityId: string;
  directionActivityName: string;
  mark: number
  coefficient: number
  studentAllowedEdit: boolean;
  hide: boolean;
  updateAt?: Date;
  dateEnd?: Date;
  dateStart?: Date;
  files: AchievementFile[];
}

export interface AchievementFile {
  id: string;
  name: string;
  updateAt: Date;
}

export interface TotalMarks {
  color: string;
  mark: number;
  name: string;
}

export interface AchievementPost {
  studentId: string;
  name: string;
  documentTypeId: string;
  files: File[];
}

export interface AchievementPut {
  id: string;
  name: string;
  studentId: string;
  documentTypeId: string;
  files?: File[] | null;
}