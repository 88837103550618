import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SpecializationService {
  private baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.education}/GetAllSpecializations`;

  constructor(private http: HttpClient) { }

  public getAll(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl);
  }
}
