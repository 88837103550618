import {EventEmitter, Injectable, Output} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DictEmailType} from "../../models/profile/dictionaries/dictEmailType.model";

@Injectable({
  providedIn: 'root'
})
export class DictEmailTypesService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictEmailType}`;

  constructor(private http: HttpClient) { }

  progress?: number;
  message?: string;
  @Output() public onUploadFinished = new EventEmitter();

  public getAllEmailTypes(): Observable<DictEmailType[]> {
    return this.http.get<DictEmailType[]>(this.baseUrl);
  }
}
