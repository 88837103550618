enum DictAchievementStatusEnumList {
  approved = 1,
  rejected,
  returned_for_revision,
  added,
  sended ,
  corrected
}

export const DictAchievementStatusEnum = DictAchievementStatusEnumList
