import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DocumentGet} from '../../models/portfolio/document.model';
import {Achievement, AchievementGet} from '../../models/portfolio/achievement.model';
import {environment} from '../../../environments/environment';
import {CreateQuery} from "../../helpers/createQuery-helper";

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  documentUrl = `${environment.apiEndpoint}${environment.portfolio.apiPaths.document}`;
  achievementUrl = `${environment.apiEndpoint}${environment.portfolio.apiPaths.achievement}`;

  constructor(private http: HttpClient) {}

  //#region Documents

  /**
   * Документы
   * @param filter Фильтры
   */
  getDocuments(filter: {studentId?: string | null; typeId?: string | null}): Observable<DocumentGet[]> {
    const query = CreateQuery(filter);

    return this.http.get<DocumentGet[]>(this.documentUrl, {params: query});
  }

  /**
   * Сохранение документа
   * @param data Информауия о документе
   */
  postDocument(data: FormData): Observable<string> {
    return this.http.post<string>(this.documentUrl, data);
  }

  /**
   * Обновление документа
   * @param data Информауия о документе
   */
  putDocument(data: FormData): Observable<boolean> {
    return this.http.put<boolean>(this.documentUrl, data);
  }

  /**
   * Удаление документа
   * @param id ID документа
   */
  deleteDocument(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.documentUrl}/${id}`);
  }

  //Download file
  downloadDocumentFile(id: string) {
    return this.http.get(`${this.documentUrl}/File/${id}`, {responseType: 'blob'});
  }

  //#endregion

  //#region Achievements

  /**
   * Документы
   * @param filter Фильтры
   */
  getSimpleAchievements(filter: {
    studentId?: string | null;
    directionActivityId?: string | null;
  }): Observable<Achievement[]> {
    const query = CreateQuery(filter);

    return this.http.get<Achievement[]>(this.achievementUrl, {
      params: query,
    });
  }

  getAdvancedAchievements(filter: { studentId?: string | null; directionActivityId?: string | null }): Observable<Achievement[]> {
    const query = CreateQuery(filter);

    return this.http.get<Achievement[]>(this.achievementUrl, { params: query });
  }

  /**
   * Сохранение достижения
   * @param data Информауия о документе
   */
  postAchievement(data: FormData): Observable<string> {
    return this.http.post<string>(this.achievementUrl, data);
  }

  /**
   * Обновление достижения
   * @param data Информауия о достижении
   */
  putAchievement(data: FormData): Observable<boolean> {
    return this.http.put<boolean>(this.achievementUrl, data);
  }

  /**
   * Удаление достижения
   * @param id ID документа
   */
  deleteAchievement(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.achievementUrl}/${id}`);
  }

  downloadAchievementFile(id: string) {
    return this.http.get(`${this.achievementUrl}/File/${id}`, {responseType: 'blob'});
  }

  //#endregion

  //#region status

  //Сохранение достижения
  postStatus(status: any): Observable<boolean> {
    return this.http.post<boolean>(this.achievementUrl + '/AddStatus', status);
  }

  //#endregion
}
