import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MfcNotificationService {
  private readonly baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.student}/GetNotificationsCount`;

  private observer = new Subject();
  public subscriber$ = this.observer.asObservable();

  constructor(private http: HttpClient) {}

  public refreshBadge() {
    this.observer.next(0);
  }

  public getNotificationCount(): Observable<number> {
    return this.http.get<number>(this.baseUrl);
  }
}
