import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Regulation} from "../../models/oferta/regulation/regulation.model";
import {SimpleModulesEnum} from "../../models/oferta/enums/simpleModuleEnum.enum";

@Injectable({
  providedIn: 'root'
})
export class RegulationService {
  baseUrl = `${environment.apiEndpoint}${environment.oferta.apiPaths.regulation}`

  constructor(private http: HttpClient) { }

  public getRegulation(dictRegulationTypeId: string, simpleModuleEnum: SimpleModulesEnum) : Observable<Regulation> {
    let params = new HttpParams();
    params = params.append('dictRegulationTypeId', dictRegulationTypeId);
    params = params.append('simpleModuleEnum', simpleModuleEnum);
    return this.http.get<Regulation>(`${this.baseUrl}/GetByEnumAndDictId`, {params});
  }
}
