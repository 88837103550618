import {EventEmitter, Injectable, Output} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Email} from "../../models/profile/contacts/emails.model";

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.email}`;

  constructor(private http: HttpClient) { }

  progress?: number;
  message?: string;
  @Output() public onUploadFinished = new EventEmitter();

  public getAllEmails(): Observable<Email[]> {
    return this.http.get<Email[]>(this.baseUrl);
  }

  public getById(studentId: string, emailId: string): Observable<Email> {
    return this.http.get<Email>(this.baseUrl + `/${studentId}/${emailId}`);
  }

  public addEmail(studentId: string, email: Email): Observable<Email> {
    return this.http.post<Email>(this.baseUrl + `/${studentId}`, email);
  }

  public updateEmail(emailId: string, studentId: string, email: Email): Observable<Email> {
    return this.http.put<Email>(this.baseUrl + `/${emailId}/${studentId}`, email);
  }

  public deleteEmail(studentId: string, emailId: string) {
    return this.http.delete(this.baseUrl + `/${studentId}/${emailId}`);
  }

  public sendActivateCode(email: string) {
    return this.http.post(this.baseUrl + `/sendActivateCode/`, { toEmail: email });
  }

  public checkActivationCode(email: string, code: string) {
    return this.http.post(this.baseUrl + `/checkCode/`, { email: email, code: code });
  }
}
