import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {createMap} from '../../../../helpers/map-helper';
import {DocumentsService} from '../../../../services/mfc/documents.service';
import {ApplicationApprovalSettings} from '../../../../models/mfc/applicationForm/approvalList.model';
import {ApplicationDocument, DocumentGroup, DocumentItem} from '../../../../models/mfc/applicationForm/document.model';
import {DictSignatoryRole} from '../../../../models/mfc/dicts/signatory-role.model';
import {ApplicationApprovalTypeEnum} from 'src/app/models/mfc/enums/application-approval-type.enum';
import {SignatoryRoleService} from '../../../../services/mfc/dicts/signatory-role.service';
import {RoleService} from '../../../../services/mfc/dicts/role.service';
import {Role} from '../../../../models/mfc/dicts/role.model';
import {saveAs} from '@progress/kendo-file-saver';
import {ApplicationFormService} from '../../../../services/mfc/application-form.service';
import {ApplicationEditFormService} from '../../../../services/mfc/application-edit-form.service';

@Component({
  selector: 'mfc-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationFormDocumentsComponent implements OnInit, OnChanges {

  @Input() approvalSettings: ApplicationApprovalSettings[] = [];
  @Input() documents: ApplicationDocument[] = [];
  @Input() applicationId = '';

  protected documentGroups: DocumentGroup[] = [];
  protected signatoryRoles: DictSignatoryRole[] = [];
  protected roles: Role[] = [];

  // Ng-Templates
  protected readonly signatureTemplate?: {approvalSettings: DocumentItem};
  protected readonly documentTemplate?: {approvalSettings: DocumentItem};

  protected signatoryRolesMap = new Map<unknown, unknown>([]);
  protected rolesMap = new Map<unknown, unknown>([]);
  protected signingsMap = new Map<ApplicationApprovalTypeEnum, string>([
    [ApplicationApprovalTypeEnum.SigningOfAnEnhancedElectronicSignature, 'Подписано усиленной ЭП'],
    [ApplicationApprovalTypeEnum.SigningASimpleElectronicSignature, 'Подписано простой ЭП'],
    [ApplicationApprovalTypeEnum.SigningAPhysicalSignature, 'Подписано физической подписью'],
  ]);

  protected readonly ApplicationApprovalTypeEnum = ApplicationApprovalTypeEnum;

  constructor(
    private cdr: ChangeDetectorRef,
    private documentsService: DocumentsService,
    private signatoryRoleService: SignatoryRoleService,
    private roleService: RoleService,
    private applicationFormService: ApplicationFormService,
    private editFormService: ApplicationEditFormService
  ) { }

  async ngOnInit() {
    await this.getSignatoryRoles();
    await this.getRoles();
    this.cdr.detectChanges();
  }

  ngOnChanges(): void {
    if (this.approvalSettings.length) {
      this.documentGroups = this.mapApprovalSettings(this.approvalSettings);
    }
  }

  private async getSignatoryRoles() {
    await lastValueFrom(this.signatoryRoleService.getSignatoryRoles()).then((response) => {
      this.signatoryRoles = response;
      this.signatoryRolesMap = createMap({value: response, valueField: 'externalId', textField: 'name'});
    });
  }

  private async getRoles() {
    await lastValueFrom(this.roleService.getRoles()).then((response) => {
      this.roles = response;
      this.rolesMap = createMap({value: response, valueField: 'id', textField: 'name'});
    });
  }

  private mapApprovalSettings(approvalSettings: ApplicationApprovalSettings[])  {
    approvalSettings = this.editFormService.mapApprovalSettings(approvalSettings);

    const documentItems = approvalSettings.map((item) => {
      return {
        ...item,
        signDate: item.completedApprovalStep?.enhancedSignatureInfo?.signDate,
        expirationDate: item.completedApprovalStep?.enhancedSignatureInfo?.certificateExpirationDate,
        document: this.documents.find(a => a.externalId === item.applicationConstructorDocumentId),
        statusName: (item.completedApprovalStep
          ? item.completedApprovalStep.approvalResult ? item.yesText : item.noText
          : item.isCurrentStage ? item.onApprovalText : item.notMarkedText) ?? ''
      } as DocumentItem;
    }).filter((a) => a.isSignatoryRequired);

    return this.groupDocuments(documentItems);
  }

  private groupDocuments(docs: DocumentItem[]) {
    const statusGroups: DocumentGroup[] = [];
    const groups = docs.reduce((acc, item) => {
      acc[item.statusName] = acc[item.statusName] ?? [];
      acc[item.statusName].push(item);
      return acc;
    }, {} as Record<string, DocumentItem[]>);
    Object.entries(groups).forEach(([key, value]) => {
      statusGroups.push({statusName: key, items: value});
    });
    return statusGroups;
  }

  protected downloadDocument(item: DocumentItem) {
    if (!item.document) {
      return;
    }

    if (item.completedApprovalStep) {
      item.approvalType === ApplicationApprovalTypeEnum.SigningOfAnEnhancedElectronicSignature
        ? this.getCompletedTemplate(item.document)
        : this.downloadDocumentByPdf(item.document);
      return;
    }

    if (item.applicationConstructorDocumentId) {
      this.documentsService.downloadDocument(this.applicationId, item.applicationConstructorDocumentId).subscribe((response) => {
        saveAs(response, item.document?.name ?? '');
      });
    }
  }

  protected getCompletedTemplate(document?: ApplicationDocument) {
    if (document?.externalId) {
      this.documentsService.getCompletedTemplate(this.applicationId, document.externalId).subscribe((response) => {
        saveAs(response, document.name);
      });
    }
  }

  protected downloadReadyDocument(document: ApplicationDocument) {
    if (document.externalId) {
      this.documentsService.downloadDocument(this.applicationId, document.externalId).subscribe((response) => {
        saveAs(response, document.name);
      });
    }
  }

  protected downloadDocumentSign(document?: ApplicationDocument) {
    if (document?.externalId) {
      this.applicationFormService.downloadDocumentSign(this.applicationId, document.externalId).subscribe((response) => {
        saveAs(response, `${document.name}.docx.sig`);
      });
    }
  }

  protected downloadDocumentByPdf(document?: ApplicationDocument) {
    if (document?.externalId) {
      this.documentsService.getApplicationDocumentByPdf(this.applicationId, document.externalId).subscribe((response) => {
        saveAs(response, document.name);
      });
    }
  }
}
