import {ApplicationApprovalSettings} from "./approvalList.model";

export interface DocumentGroup {
  statusName: string;
  items: ApplicationApprovalSettings[];
}

export interface ApplicationDocument {
  externalId?: string;
  name: string;
  documentType?: number;
  expirationDate?: Date;
}

export interface DocumentItem extends ApplicationApprovalSettings {
  statusName: string;
  document?: ApplicationDocument;
  expirationDate?: Date;
  signDate?: Date;
}

export class ApplicationDocumentList {
  signature: ApplicationDocument[] = [];
  ready: ApplicationDocument[] = [];
}
