import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Faculty} from "../../../models/mfc/dicts/faculty.model";

@Injectable({
  providedIn: 'root'
})
export class FacultyService {
  private baseUrl = `${environment.apiEndpoint}${environment.mfc.apiPaths.externalDict}/Faculty/`;

  constructor(private http: HttpClient) {}

  public getFaculties() {
    return this.http.get<Faculty[]>(this.baseUrl);
  }
}
