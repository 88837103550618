import {EventEmitter, Injectable, Output} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {DictEmployerPost} from "../../models/employment/dictEmployerPost.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DictEmployerPostService {
  baseUrl = `${environment.apiEndpoint}${environment.employment.apiPaths.dictEmployerPost}`;

  constructor(private http: HttpClient) { }

  progress?: number;
  message?: string;
  @Output() public onUploadFinished = new EventEmitter();

  public getAllEmployerPosts(): Observable<DictEmployerPost[]> {
    return this.http.get<DictEmployerPost[]>(this.baseUrl);
  }
}
