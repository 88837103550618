import { Component, Input, OnInit } from '@angular/core';
import { LKStudPerson } from 'src/app/models/profile/lkStudPerson.model';

@Component({
  selector: 'student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent {

  @Input() public student!: {
    lkStudentId: number,
    lkStudentExternalId: string,
    studentNumber: string,
    studPersonId: string,
    studPerson: {
      firstName: string;
      lastName: string;
      middleName: string;
      isMale: boolean;
      birthday: Date
    }
  };

}
