import {Component, OnInit} from '@angular/core';
import {lastValueFrom} from "rxjs";
import {DisplaySystemSettingService} from "../../../services/LKStudent/display-system-setting.service";
import {DisplaySystemSettingEnum} from "../../../models/home/enums/display-system-setting.enum";

@Component({
  selector: 'app-achievements-home',
  templateUrl: './achievements-home.component.html',
  styleUrls: ['./achievements-home.component.scss'],
})
export class AchievementsHomeComponent implements OnInit {

  public systemSetting = DisplaySystemSettingEnum;
  protected isSimple = false;
  public enum = DisplaySystemSettingEnum;

  constructor(private displaySystemSettingService: DisplaySystemSettingService) {}

  async ngOnInit() {
    await this.getAchievementsSetting();
  }

  private async getAchievementsSetting() {
    await lastValueFrom(this.displaySystemSettingService.GetAllSystemDisplaySettings()).then((value) => {
      this.displaySystemSettingService.displaySettings$.next(value);
    });
  }

  public isAdvancedVersion(field: number): boolean {
    let portfolioSetting =  this.displaySystemSettingService.displaySettings$.value.find((x) => x.type === field);
    if (portfolioSetting != undefined) {
      return portfolioSetting.isDisplay;
    }
    return false;
  }
}
