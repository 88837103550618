import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUnique'
})
export class FilterUniquePipe implements PipeTransform {

  transform(value: any, args?: any[]): any {
    
    // Remove the duplicate elements
    let uniqueArray = value.filter(function (el: any, index: any, array: string | any[]) { 
      return array.indexOf (el) == index;
    });

    return uniqueArray;
  }

}
