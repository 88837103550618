import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {TokenStorageService} from "../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Location} from "@angular/common";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SpecialModuleGuard implements CanActivate {

  constructor(private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService,
              private location: Location,
              private router: Router) {
  }

  canActivate() {
    const token = this.tokenStore.getAuthToken()
    if (token !== null) {
      const user = this.jwtHelper.decodeToken(token);

      if ( !environment.services.includes('electroresources'))
      {
        this.router.navigate(['/home']);
        return false;
      }

      return true;
    }

    localStorage.setItem('last_url', this.location.path())
    return false;
  }
}
