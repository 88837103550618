<div class="form">
  <h1>Дисциплина</h1>
  <button (click)="onCancel()" kendoButton>Закрыть</button>
  <ng-container *ngFor="let field of fields; trackBy: trackByFn">
    <div class="field">
      <h3>{{field.name}}</h3>
      <p>{{field.value}}</p>
    </div>
  </ng-container>
</div>
