import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {LKDictOrganization} from "../../../models/profile/dictionaries/lkDictOrganization.model";
import {DictEmployerPost} from "../../../models/employment/dictEmployerPost.model";
import {FileRestrictions} from "@progress/kendo-angular-upload";
import {ActivatedRoute, Router} from "@angular/router";
import {EmploymentService} from "../../../services/employment/employment.service";
import {Employment} from "../../../models/employment/employment.model";
import {TimeZoneFix} from "../../../helpers/date-helper";
import {NotificationsService} from "../../../services/Notifications/notifications.service";
import {LKStudPerson} from "../../../models/profile/lkStudPerson.model";
import {LKStudent} from "../../../models/profile/lkstudent.model";
import {LKStudentService} from "../../../services/LKStudent/lkstudent.service";
import {LKDictOrganizationService} from "../../../services/LKStudent/dictionaries/lkdict-organization.service";
import {DictEmployerPostService} from "../../../services/employment/dictEmployerPost.service";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {saveAs} from "@progress/kendo-file-saver";

@Component({
  selector: 'employment-info',
  templateUrl: './employment-info.component.html',
  styleUrls: ['./employment-info.component.scss']
})
export class EmploymentInfoComponent implements OnInit {

  public studentId!: string;
  public employmentId: string | null = null;
  public isEditingMode: boolean = false;

  public employmentForm: UntypedFormGroup = this.getFormGroup();
  public employment?: Employment = undefined;
  public isCurrent: boolean = false;

  public organizations: LKDictOrganization[] = [];
  public employerPosts: DictEmployerPost[] = [];

  public files?: Array<File>;

  public restrictions: FileRestrictions = {
    allowedExtensions: [".docx", ".pdf"],
  };

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "",
    studPersonId: "",
    studPerson: this.studPerson
  }

  async ngOnInit() {
    await this.getStudentByLogin();
    this.getAllOrganizations();
    this.getAllEmployerPosts();
  }

  constructor( private router: Router,
               private studentService: LKStudentService,
               private employmentService: EmploymentService,
               private organizationService: LKDictOrganizationService,
               private employerPostService: DictEmployerPostService,
               private notificationService: NotificationsService,
               private route: ActivatedRoute,
  ) { }

  public moveToInfo() : void {
    this.router.navigate(['employment']);
  }

  public onSave(e: PointerEvent): void {
    if (this.files && this.files.length > 0) {
      this.employmentForm.get('scanFileFullName')?.setValue(this.files[0].name);
      this.employmentForm.get('scanFileFullName')?.updateValueAndValidity();
    }

    if (!this.employmentForm.valid) {
      this.employmentForm.markAllAsTouched();
      this.notificationService.showError('Не все обязательные поля заполнены');
      return;
    }
    e.preventDefault();
    this.saveEmployment();
  }

  public saveEmployment() {
    this.employmentForm.value.dateStart = TimeZoneFix(this.employmentForm.value.dateStart);
    if (this.employmentForm?.value.dateFinish) {
      this.employmentForm.value.dateFinish = TimeZoneFix(this.employmentForm.value.dateFinish);
    }
    if (this.employmentForm?.value.dateStart &&
      this.employmentForm?.value.dateFinish &&
      this.employmentForm?.value.dateStart > this.employmentForm?.value.dateFinish) {
      this.notificationService.showError("Дата трудоустройства должна быть меньше даты увольнения.");
    } else {
      this.employmentForm.value.studentId = this.studentId;
      const formData = new FormData();
      formData.append(`StudentId`, this.employmentForm?.value.studentId)
      formData.append(`DictOrganizationId`, this.employmentForm?.value.dictOrganizationId)
      formData.append(`DictEmployerPostId`, this.employmentForm?.value.dictEmployerPostId)
      formData.append(`DateStart`, this.employmentForm?.value.dateStart?.toISOString())
      formData.append(`IsCurrent`, this.employmentForm?.value.isCurrent)
      if (this.employmentForm?.value.dateFinish) {
        formData.append(`DateFinish`, this.employmentForm?.value.dateFinish?.toISOString())
      }
      if (this.files && this.files.length > 0) {
        formData.append(`ScanFileFullName`, this.files[0].name);
        formData.append(`ScanFile`, this.files[0], this.files[0].name);
      }
      else {
        formData.append(`ScanFileFullName`, this.employmentForm?.value.scanFileFullName);
      }

      this.isEditingMode
        ? this.employmentService.updateEmployment(this.employmentId!, formData)
          .subscribe({
            next:() => {
              this.notificationService.showSuccess("Сохранено");
              this.moveToInfo();
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения');
            }}
          )
        : this.employmentService.addEmployment(formData)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess('Трудовая деятельность была успешно добавлена!');
              this.moveToInfo();
            },
            error: () => {
              this.notificationService.showError('Не удалось добавить трудовую деятельность');
            },
          });
    }
  }

  public getParamsFromRoute() {
    this.route.params.subscribe(params => {
      this.employmentId = params['id'] || null;

      if (this.employmentId) {
        this.isEditingMode = true;
        this.getEmploymentById(this.employmentId);
      }

    });
  }

  private getEmploymentById(id: string) {
    this.employmentService.getEmploymentById(id).subscribe({
      next: response => {
        this.employment = response;
        this.employmentForm = this.getFormGroup();
      },
      error: err => {
        this.notificationService.showError(err);
      }
    })
  }

  public async getStudentByLogin() {
    const changeStudent = Number(localStorage.getItem('changeStudent'));
    await this.studentService.getCurrentStudent().toPromise().then(response => {
      if (!response) return;

      this.students = response;
      this.studentModel = changeStudent ? this.students[changeStudent-1] : this.students[0];
      this.studentId = this.studentModel.externalId;
    }, reason => {
      this.notificationService.showError('Не удалось загрузить информацию о студенте для просмотра');
    });

    this.getParamsFromRoute();
  }

  private getAllOrganizations() {
    this.organizationService.getDictOrganizations().subscribe({
      next: response => this.organizations = response,
      error: err => {
        this.notificationService.showError(err);
      }
    })
  }

  public downloadFile(id: string) {
    const fileName = this.employment?.scanFile.fileFullName;
    if(fileName) {
      this.employmentService.getEmploymentFile(id).subscribe(
        (response: BlobPart) => {
          const blob: any = new Blob([response], { type: `Content-Type', 'application/octet-stream` });

          saveAs(blob, fileName);
        }
      );
    }
  }

  private getAllEmployerPosts() {
    this.employerPostService.getAllEmployerPosts().subscribe({
      next: response => this.employerPosts = response,
      error: err => {
        this.notificationService.showError(err);
      }
    })
  }

  private getFormGroup() {
    return new FormGroup({
      employmentId: new FormControl<string|null>(this.employment?.id ?? null),
      studentId: new FormControl<string>(this.studentId),
      dictOrganizationId: new FormControl<string|null>(this.employment?.dictOrganizationId ?? null, Validators.required),
      dictEmployerPostId: new FormControl<string|null>(this.employment?.dictEmployerPostId ?? null, Validators.required),
      dateStart: new FormControl<Date|null>(this.employment?.dateStart ? new Date(this.employment.dateStart) : null, Validators.required),
      dateFinish: new FormControl<Date|null>(this.employment?.dateFinish ? new Date(this.employment.dateFinish) : null),
      scanFileFullName: new FormControl<string|null>(this.employment?.scanFile.fileFullName ?? null, Validators.required),
      file: new FormControl(null),
      isCurrent: new FormControl<boolean>(this.employment?.isCurrent ?? false, Validators.required)
    });
  }
}
